import { getAcceptedCountries } from '@/api/api';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import { useOutsideClick } from '@/hooks/useClickOutside';
import { Icon } from '../core/Icon/Icon';
import styles from './AutoCompleteCountries.module.scss';

type Country = {
    value: string;
    label: string;
};

type AutoCompleteLocationProps = {
    control: Control<any, any>;
    name: string;
    className?: string;
    rules?: any;
    isMulti?: boolean;
    setValue: any;
    defaultValue?: Country[] | null;
    placeholder?: string;
};

const AutoCompleteCountries: React.FC<AutoCompleteLocationProps> = ({
    control,
    name,
    rules,
    className,
    defaultValue = [],
    isMulti = false,
    setValue,
    placeholder,
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const rootRef = useOutsideClick(() => setShowDropdown(false));

    const [internalValue, setInternalValue] = useState<Country[]>([]);
    const [label, setLabel] = useState<string>('');
    const [countries, setCountries] = useState<Country[]>([]);
    const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);

    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);

    // ADD & REMOVE VALUE
    const selectCountry = (label: string, selection: any) => {
        setInternalValue(selection);
        setShowDropdown(false);
        setHighlightedIndex(-1);
        if (isMulti) {
            handleInputChange('');
            setLabel('');
        } else {
            setLabel(label);
        }
    };
    const handleRemoveCountry = (countryToRemove: Country, onChange: (value: any) => void) => {
        const updatedValue = internalValue.filter(country => country.value !== countryToRemove.value);
        setInternalValue(updatedValue);
        onChange(updatedValue);
    };

    // HANDLE DROPDOWN WITH KEYBOARD
    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (!showDropdown) return;

        switch (e.key) {
            case 'ArrowDown':
                setHighlightedIndex(prevIndex =>
                    prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex,
                );
                break;
            case 'ArrowUp':
                setHighlightedIndex(prevIndex => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                break;
            case 'Enter':
                if (highlightedIndex >= 0) {
                    const selectedCountry = filteredCountries[highlightedIndex];
                    const selectedCountries = isMulti ? [...internalValue, selectedCountry] : [selectedCountry];
                    selectCountry(selectedCountry.label, selectedCountries);
                    setValue(name, selectedCountries);
                    inputRef.current?.blur();
                }
                break;
        }
    };

    // HANDLE INPUT VALUE
    const handleInputChange = useCallback((value: string) => {
        setLabel(value);
        setHighlightedIndex(-1);
    }, []);

    // DEBOUNCE DROPDOWN FILTERING
    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            if (label) {
                const filtered = countries
                    .filter(
                        country =>
                            country.label.toLowerCase().includes(label.toLowerCase()) &&
                            !internalValue.some(selectedCountry => selectedCountry.value === country.value),
                    )
                    .sort((a, b) => {
                        const startsWithValue = (str: string) => str.toLowerCase().startsWith(label.toLowerCase());
                        return startsWithValue(a.label) && !startsWithValue(b.label)
                            ? -1
                            : !startsWithValue(a.label) && startsWithValue(b.label)
                            ? 1
                            : 0;
                    })
                    .slice(0, 10);

                setFilteredCountries(filtered);
                setShowDropdown(true);
            } else {
                setFilteredCountries([]);
                setShowDropdown(false);
            }
        }, 300);

        return () => clearTimeout(debounceTimeout);
    }, [label, countries, internalValue]);

    // FETCH COUNTRIES LIST
    const fetchCountries = useCallback(async () => {
        try {
            const fetchedCountries = await getAcceptedCountries();
            setCountries(fetchedCountries.map(([value, label]) => ({ value, label })));
        } catch (error) {
            console.error('Failed to fetch countries', error);
        }
    }, []);
    useEffect(() => {
        fetchCountries();
        if (defaultValue) {
            setInternalValue(defaultValue);
            setValue(name, defaultValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div ref={rootRef} className={clsx(className, styles.root)}>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field: { onChange, value, ...field }, fieldState }) => (
                    <div
                        className={clsx(styles.inputWrapper, {
                            [styles.isFocus]: showDropdown,
                            [styles.error]: fieldState.error,
                        })}
                    >
                        {internalValue.length > 0 && (
                            <div className={styles.internalValues}>
                                <div className={styles.valueWrapper}>
                                    {isMulti ? (
                                        internalValue?.map((selectedCountry: any) => (
                                            <span
                                                key={selectedCountry.label}
                                                className={styles.selectedCountry}
                                                onClick={() => handleRemoveCountry(selectedCountry, onChange)}
                                            >
                                                {selectedCountry.label} <Icon name="close" />
                                            </span>
                                        ))
                                    ) : (
                                        <div>{internalValue?.[0]?.label}</div>
                                    )}
                                </div>
                                <Icon
                                    className={styles.closeIcon}
                                    name="close"
                                    onClick={() => {
                                        setInternalValue([]), setLabel(''), setValue(name, []);
                                    }}
                                />
                            </div>
                        )}

                        {!isMulti && internalValue.length > 0 ? null : (
                            <input
                                {...field}
                                ref={inputRef}
                                value={label}
                                onChange={e => handleInputChange(e.target.value)}
                                onKeyDown={handleKeyDown}
                                className={clsx(styles.input, fieldState.error && styles.invalid)}
                                placeholder={placeholder || 'Select...'}
                                onFocus={() => setShowDropdown(true)}
                                type="text"
                                autoComplete="false"
                            />
                        )}

                        {showDropdown && filteredCountries.length > 0 && (
                            <ul className={styles.dropdown}>
                                {filteredCountries.map((country, index) => (
                                    <li
                                        onMouseEnter={() => setHighlightedIndex(index)}
                                        onTouchStart={() => setHighlightedIndex(index)}
                                        key={country.value}
                                        onClick={() => {
                                            const selectedCountries = isMulti ? [...internalValue, country] : [country];
                                            selectCountry(country.label, selectedCountries);
                                            onChange(selectedCountries);
                                        }}
                                        className={clsx(styles.liSelect, {
                                            [styles.highlighted]: index === highlightedIndex,
                                        })}
                                    >
                                        {country.label}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                )}
            />
        </div>
    );
};

export default AutoCompleteCountries;
