import { NEXT_PUBLIC_API_URL } from '@/config/env';
import fetchClient from '@/lib/auth/fetch-client';
import { Trip } from '@/types/trip';
import { FullInfluencerProfile, Influencer } from '@/types/types';
import { CookieHelper } from '@/utils/cookies';

export async function getInfluencerInfo(uuid: string): Promise<Influencer> {
    const authorData = await fetch(`${NEXT_PUBLIC_API_URL}/v1/auth/influencer/${uuid}`, {
        method: 'GET',
        next: { revalidate: 6 },
    }).then(r => r.json());
    return authorData;
}

export async function getFullInfluencerProfile(): Promise<FullInfluencerProfile> {
    const res = await fetchClient('/v1/auth/user/me', { method: 'GET' });
    return await res.json();
}

export async function getTripData(id: string | string[] | undefined): Promise<Trip | undefined> {
    const response = await fetchClient(`/v1/trip/${id}`, {
        method: 'GET',
        next: { revalidate: 6 },
    }).then(r => r.json());
    return response;
}

export async function getAcceptedCountries(): Promise<[string, string][]> {
    const response = await fetchClient(`/v1/countries/`, {
        method: 'GET',
    }).then(r => r.json());
    return response;
}

export async function updateTripData(uuid: string, body: any) {
    const response = await fetchClient(`/v1/trip/${uuid}/`, {
        method: 'PATCH',
        body: JSON.stringify(body),
        next: { revalidate: 6 },
    }).then(r => r.json());
    return response;
}
