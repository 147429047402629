import { FullInfluencerProfile, SessionType } from '@/types/types';
import { Session } from 'next-auth';

export function addNumericSuffix(n: number): string {
    //input will be max 10
    const j = n % 10;
    if (j === 1) {
        return n + 'st';
    } else if (j === 2) {
        return n + 'nd';
    } else if (j === 3) {
        return n + 'rd';
    }
    return n + 'th';
}
export function formatAccommodationTag(tag: string): string {
    if (tag === 'Eco-friendly') {
        return 'ECO_FRIENDLY';
    }
    return tag.toUpperCase();
}
export function capitalizeArrayItems(arr: string[], isSeasons?: boolean): string[] {
    if (isSeasons) {
        const newSeasons = arr.map(
            str =>
                str.split('-')[0].charAt(0) +
                str.split('-')[0].slice(1).toLowerCase() +
                '-' +
                str.split('-')[1].charAt(0) +
                str.split('-')[1].slice(1).toLowerCase(),
        );
        return newSeasons;
    }
    const newArr = arr.map(str => str.charAt(0) + str.slice(1).toLowerCase());
    return newArr;
}

export function formatFullProfileData(data?: FullInfluencerProfile | null): SessionType | null {
    if (!data) return null;
    return {
        user: {
            name: `${data?.first_name} ${data?.last_name}`,
            email: data.email,
            image: data?.profile?.image || '',
            uuid: data?.uuid || '',
        },
    };
}
