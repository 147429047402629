'use client';

import React from 'react';
import { Popup } from '@/components/core/Popup/Popup';
import { NEXT_PUBLIC_SITE_URL } from '@/config/env';
import { useState } from 'react';
import { FiShare2 } from 'react-icons/fi';
import styles from './ShareButton.module.scss';

// Define the props types for ShareButton
interface ShareButtonProps {
    ButtonComponent?: any;
    buttonProps?: any;
    showTick?: boolean;

    link: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ ButtonComponent, buttonProps = {}, showTick = true, link }) => {
    const [show, setShow] = useState(false);

    const handleClick = () => {
        setShow(true);
        navigator.clipboard.writeText(`${NEXT_PUBLIC_SITE_URL}${link}`);
    };

    // Default content using FiShare2 when no ButtonComponent is provided
    const DefaultIcon = () => <FiShare2 style={{ cursor: 'pointer' }} onClick={handleClick} />;

    return (
        <div className={styles.root}>
            {ButtonComponent ? <ButtonComponent {...buttonProps} onClick={handleClick} /> : <DefaultIcon />}
            <Popup className={styles.popup} show={show} closePopup={setShow} showTick={showTick} />
        </div>
    );
};

export default ShareButton;
