'use client';
import AutoCompleteCountries from '@/components/AutoCompleteCountries/AutoCompleteCountries';
import { useRouter, useSearchParams } from 'next/navigation';
import { forwardRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styles from './SearchBar.module.scss';

type SearchFormType = {
    country: any;
};

const SearchBar = forwardRef<HTMLDivElement>((props, ref) => {
    const searchParams = useSearchParams();
    const router = useRouter();
    const { control, watch, setValue } = useForm<SearchFormType>();
    const country = watch('country');
    console.log(country);
    useEffect(() => {
        if (country?.[0].label) {
            const params = new URLSearchParams(searchParams.toString());
            params.set('query', country?.[0]?.label);
            params.set('page', '1');
            router.push(`/search?${params.toString()}`);
        }
    }, [country, router, searchParams]);

    return (
        <div ref={ref}>
            <div className={styles.container}>
                <AutoCompleteCountries
                    className={styles.autocomplete}
                    name="country"
                    control={control}
                    setValue={setValue}
                    placeholder="Select a country..."
                />
            </div>
        </div>
    );
});

SearchBar.displayName = 'SearchBar';

export default SearchBar;
