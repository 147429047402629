import { NEXT_PUBLIC_API_URL } from '@/config/env';
import { getSession, signOut } from 'next-auth/react';

type FetchOverrides = {
    baseUrl?: string;
    token?: string;
};

async function fetchClient(url: string | URL | globalThis.Request, init?: RequestInit, overrides?: FetchOverrides) {
    try {
        const session = await getSession();
        const token = overrides?.token || session?.accessToken;
        const baseUrl = overrides?.baseUrl || NEXT_PUBLIC_API_URL;

        let headers: HeadersInit = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...init?.headers,
        };

        if (token) {
            headers = {
                ...headers,
                Authorization: 'Bearer ' + token,
            };
        }
        const response = await fetch(baseUrl + url.toString(), {
            ...init,
            headers,
        });

        if (!response.ok) {
            throw response;
        }

        return response;
    } catch (error) {
        if (error instanceof Response) {
            if (error.status === 401) {
                signOut();
            }

            throw error;
        }

        throw new Error('Failed to fetch data', { cause: error });
    }
}

export default fetchClient;
